import { Variants, motion, TargetAndTransition } from "framer-motion";
import React from "react";

const variants: Variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const itemVariants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: { y: { stiffness: 1000, velocity: -100 } },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: { y: { stiffness: 1000 } },
  },
};

const hoverVariants: TargetAndTransition = {
  scale: 1.1, // Scale the link up slightly when hovered
  transition: {
    duration: 0.3,
    yoyo: Infinity, // Animate back and forth repeatedly
  },
};

const Links: React.FC = () => {
  const links = ["Home", "Services", "Contact"];

  // Function to handle the click event and scroll to the respective section
  const handleClick = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <motion.div
      className="absolute w-full h-full flex flex-col justify-center content-center gap-9"
      variants={variants}
    >
      {links.map((link, index) => (
        <motion.a
          variants={itemVariants}
          whileHover={hoverVariants}
          className="text-4xl font-medium self-center cursor-pointer"
          key={index}
          onClick={() => handleClick(link)}
        >
          {link}
        </motion.a>
      ))}
    </motion.div>
  );
};

export default Links;
