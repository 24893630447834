import Lottie from "lottie-react";
import { motion } from "framer-motion"; // Import from Framer Motion
import { InView } from "react-intersection-observer"; // Import InView
import animationData from "./app_animation.json";
import { slideInFromRight } from "../../../../utils/motion";
import WhatWeDoText from "./WhatWeDoText";

function WebAndMobileDevelopment() {
  return (
    <InView threshold={0.3} triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.section
          ref={ref}
          className=" md:h-auto md:flex-row-reverse md:mt-48 flex-col flex w-full h-screen items-center  justify-between bg-dark-500 mt-24"
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          {/* Text Container */}
          <WhatWeDoText
            heading="Web & Mobile Development"
            describeText="We build custom web and mobile applications that are tailored to your business needs.
            Our team of experts will help you bring your ideas to life."
          />

          {/* Animation Container */}
          <motion.div
            className=" md:justify-start flex-grow flex justify-center items-center"
            variants={slideInFromRight(0, 0.9)}
          >
            <Lottie
              className="lg:w-2/3 w-full h-auto"
              animationData={animationData}
            />
          </motion.div>
        </motion.section>
      )}
    </InView>
  );
}

export default WebAndMobileDevelopment;
