import { motion } from "framer-motion";
import { slideInFromLeft } from "../../../../utils/motion";
import { InView } from "react-intersection-observer";
import WhatWeDoText from "./WhatWeDoText";

function ArtificialIntelligence() {
  return (
    <InView threshold={0.3} triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.section
          ref={ref}
          className="md:h-auto md:flex-row-reverse flex flex-col-reverse w-full h-screen pb-8 items-center justify-between bg-dark-500"
          initial="hidden"
          animate={inView ? "visible" : "hidden"} // Animate when inView is true
        >
          {/* Image Container */}
          <motion.div
            variants={slideInFromLeft(0, 1.3)}
            className=" md:justify-end flex-grow flex justify-center items-center"
          >
            <img
              src="/hand_ai.png"
              alt="Artificial Intelligence concept"
              className="w-full lg:w-full"
            />

            {/* <AiHand /> */}

            {/* <video
              src="./ai_hand_2.webm"
              autoPlay
              loop
              muted
              typeof="video/webm"
              className="w-full"
            /> */}
          </motion.div>

          {/* Text Container */}
          <WhatWeDoText
            heading="Artificial Intelligence"
            describeText="Leading the way in AI Solutions, , our custom, cutting-edge technology transforms your projects.
              Step into the future with us."
          />
        </motion.section>
      )}
    </InView>
  );
}

export default ArtificialIntelligence;
