import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";

// Define a validation schema using Yup
const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
});

function LetsWorkTogether() {

  const handleSubmit = async (values: { name: string; email: string; message: string }, actions: { setSubmitting: (arg0: boolean) => void; }) => {
    try {

      const sendContactEmail = httpsCallable(functions, 'sendContactEmail');
      await sendContactEmail(values);
      alert('Your message has been sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send your message. Please try again.');
    }
    actions.setSubmitting(false);
  };

  const formRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLDivElement>(null);
  
  const [bottomPosition, setBottomPosition] = useState('-50%'); // Initial state

  const adjustVideoPosition = () => {
    if(videoRef.current){
      const videoHeight = videoRef.current.clientHeight;

      setBottomPosition(`-${videoHeight / 2}px`);
    }
  }

  useEffect(() => {
    const handleFirstInteraction = () => {
      // Play the video manually here
      const videoElement = document.querySelector('video');
      if (videoElement) {
        videoElement.play();
      }
      // Remove the event listener after the first interaction
      document.removeEventListener('click', handleFirstInteraction);
    };
  
    document.addEventListener('click', handleFirstInteraction);
  
    return () => {
      document.removeEventListener('click', handleFirstInteraction);
    };
  }, []);
  

  useEffect(() => {
    adjustVideoPosition();
    // Adjust position on window resize
    window.addEventListener('resize', adjustVideoPosition);
    return () => window.removeEventListener('resize', adjustVideoPosition);
  }, []);


  return (
    <div  ref={videoRef} className="flex flex-col items-center justify-center mt-28  w-full  h-screen overflow-hidden relative">
      <div ref={formRef} className="flex flex-col md:flex-row px-8 xl:px-36 lg:px-24   pb-44  items-start md:items-center w-full  mx-auto space-y-8 md:space-y-0 md:space-x-8 z-10">
        {/* Text Container */}
        <div className="space-y-4 flex-1">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white">
            Let's Work Together
          </h1>
          <p className="text-md md:text-xl text-white">
            We are here to help you with your project.
          </p>
          <p className="text-md md:text-xl text-white">
            <strong>Email:</strong> <br/> sales@jiveltd.com
          </p>
        </div>

        {/* Form Container */}
        <div className="flex-1 w-full z-[3]">
          <Formik
            initialValues={{ name: '', email: '', message: '' }}
            validationSchema={ContactSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="grid gap-4">
                <Field type="text" name="name" placeholder="Name" className="px-4 py-2 w-full border border-gray-500 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                <ErrorMessage name="name" component="div" className="text-red-500" />

                <Field type="email" name="email" placeholder="Email" className="px-4 py-2 w-full border border-gray-500 rounded-md bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                <ErrorMessage name="email" component="div" className="text-red-500" />

                <Field as="textarea" name="message" placeholder="Message" className="px-4 py-2 w-full border border-gray-500 rounded-md bg-gray-800 text-white h-36 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                <ErrorMessage name="message" component="div" className="text-red-500" />

                <button type="submit" disabled={isSubmitting} className="px-4 py-2 w-full bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                  Send
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      
      {/* Animation Container */}
      <div  style={{bottom:bottomPosition}} className="w-full h-full absolute">
      <video
        autoPlay
        muted
        loop
        playsInline // Required for iOS
        className=" bottom-0 relative w-full h-full z-[0] object-cover"
      >
        <source src="/blackhole.webm" type="video/webm" />
      </video>
      </div>
    </div>
  );
}

export default LetsWorkTogether;
