import { useCallback, useLayoutEffect, useRef, useState } from "react";
import "./Parallax.css";
import { motion } from "framer-motion";
import { slideInFromLeft } from "../../../../utils/motion";
import { InView } from "react-intersection-observer";
import WaterDropEffectAnimation from "./WaterDropEffect";
import { useElementRefs } from "../../ElementsRefContext";

type StyleForParallax = {
  top: string;
  iconWidth: string;
  iconHeight: string;
  paddingBottomText: string;
};

export const Parallax = () => {
  const imgRef = useRef<HTMLImageElement>(null);
  const imgContainerRef = useRef<HTMLDivElement>(null);

  const { elementRefs } = useElementRefs();




  const [style, setStyle] = useState<StyleForParallax>({
    top: "0",
    iconWidth: "0",
    iconHeight: "0",
    paddingBottomText: "0px",
  });

  const updateLayout = useCallback(() => {


    const mediumScreen = window.innerWidth >= 768;
    
    if (imgContainerRef.current && elementRefs?.heroTextWrapper?.current) {


      if(!mediumScreen){
        setStyle({
          top: "0",
          iconWidth: imgContainerRef.current.getBoundingClientRect().width + "px",
          iconHeight: "auto",
          paddingBottomText: "0px",
          

        });
        return;
      }

      const heroTextWrapper = elementRefs.heroTextWrapper.current;

      const imgRect = imgContainerRef.current.getBoundingClientRect();
      const textRect = heroTextWrapper.getBoundingClientRect();
    
      // Calculating vertical distance
      // This calculation assumes one element is above the other; adjust as needed
      const verticalDistance = Math.abs(imgRect.top - textRect.bottom);


      const maxHeigth = imgContainerRef.current.getBoundingClientRect().height + verticalDistance;
    
      const width = imgContainerRef.current.getBoundingClientRect().width;
      const height = imgContainerRef.current.getBoundingClientRect().height;
      

      const newHeight = maxHeigth;

     let top = 0;
      if(newHeight > height){
        top = (height - newHeight);
      }

      let paddingBottomText = `${verticalDistance}px`;
      if (width * 0.8 > imgRect.height) {
        paddingBottomText = `${width - (imgRect.height / width) * width}px`;
      }

      const newStyle = {
        top: `${top}px`,
        iconWidth: `${width}px`,
        iconHeight: `${newHeight}px`,
        paddingBottomText: paddingBottomText,
      };

      setStyle(newStyle);


    }else{
    }
  },[elementRefs.heroTextWrapper]);

  // Use `useLayoutEffect` to ensure measurements and updates are accurate after DOM changes
  useLayoutEffect(() => {
    updateLayout();
    // Adding an event listener for resize to re-calculate on window resize
    window.addEventListener("resize", updateLayout);

    return () => {
      window.removeEventListener("resize", updateLayout);
    };
  }, [updateLayout]);
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      className="xl:pr-36 lg:pr-24 md:pr-8 md:flex-row relative md:mt-0 flex justify-between z-[3] flex-col-reverse"
    >
      <div ref={imgContainerRef}  className="md:pb-96 w-full  flex-grow z-[3]">
        <motion.div
          variants={slideInFromLeft(0, 0.9)}
          style={{
            top: style.top,
            width: style.iconWidth,
            height: style.iconHeight,
          }}
          className="md:absolute w-full left-0 flex justify-center items-center z-[5] overflow-visible"
        >
          <div className=" absolute w-full h-full">
          <WaterDropEffectAnimation numCircles={2}/>
          </div>
          <img
            ref={imgRef}
            src="/mainIconsdark.svg"
            alt="work icons"
            className="h-full w-full" // Responsive image class
          >
          </img>
        </motion.div>
      </div>

      <div className="h-full flex flex-col gap-5  justify-start items-center">
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              variants={slideInFromLeft(0, 0.9)}
              className="flex flex-col gap-6 mt-6 xl:text-8xl md:text-6xl md:text-start text-5xl font-bold text-white h-auto text-center"
            >
              {/* Visible on medium screens and above */}
              <span className="hidden lg:inline">
                What&nbsp;
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500">
                  Solutions
                </span>
              </span>
              {/* Visible on small screens */}
              <span className="lg:hidden">What</span>
              <span className="lg:hidden text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500">
                Solutions
              </span>
              {/* Visible on all screens */}
              <span>Do We Offer?</span>
            </motion.div>
          )}
        </InView>
      </div>
    </motion.div>
  );
};
