import React from "react";
import NavBar from "../../components/Navigation/NavBar";
import HeroSection from "./HomeComponents/Hero/HeroSection";
import "./Home.css";
import { Parallax } from "./HomeComponents/Parallax/Parallax";
import WhatWeDo from "./HomeComponents/WhatWeDo/WhatWeDo";
import StarsCanvas from "../../components/StarBackground";
import LetsWorkTogether from "./HomeComponents/LetsWorkTogether/LetsWorkTogether";
import { ElementRefsProvider } from "./ElementsRefContext";

const Home: React.FC = () => {
  return (
    <ElementRefsProvider>
      <div className="  bg-[#030014] w-screen overflow-hidden ">
        {/* Set the background color for the whole page */}
        <div className="hero-background">
          <StarsCanvas />
          <section
            id="Home"
            className="flex flex-col h-full relative content-start overflow-x-hidden overflow-y-hidden"
          >
            <NavBar />
            <HeroSection />
          </section>
        </div>
        <section id="Services" className="">
          <Parallax />
        </section>
        <section>
          <WhatWeDo />
        </section>
        <section id="Contact" className=" h-screen">
          <LetsWorkTogether />
        </section>
      </div>
    </ElementRefsProvider>
  );
};

export default Home;
