import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Variants, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./HeroSection.css";
import HeroImageLines from "./HeroImageLines";
import { useElementRefs } from "../../ElementsRefContext";

interface Style {
  right?: string;
  rightFace?: string;
  top?: string;
}


const heroImageVariants: Variants = {
  initial: {
    x: "+120%", // Start off-screen to the right. Adjust as needed based on your layout.
  },
  animate: {
    x: 0, // Animate to its intended position.
    transition: {
      duration: 2.5, // Adjust this value to make it faster or slower.
      type: "spring", // This creates a smooth animation. Adjust the type as needed.
      stifness: 50, // Adjust this value to make it more or less bouncy.
    },
  },
};


const textVariantsContainer: Variants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.3,
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const textVariants: Variants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const HeroSection = () => {
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const scrollToContact = () => {
    const section = document.getElementById('Contact');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const refImageContainer = React.useRef<HTMLDivElement>(null);
  const refLines = React.useRef<HTMLDivElement>(null);
  const refFace = React.useRef<HTMLImageElement>(null);

  const [style, setStyle] = useState<Style>({});

  const textWrapperRef = useRef<HTMLDivElement>(null);
  const { registerRef } = useElementRefs();

  const [imagesLoaded, setImagesLoaded] = useState({
    heroImage: false,
  });
  

  useEffect(() => {
    if (textWrapperRef.current) {
      registerRef("heroTextWrapper", textWrapperRef);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textWrapperRef]);

  const updateStyleForBreakpoint = useCallback(() => {
    const screenWidth = window.innerWidth;
    const isMdBreakpoint = screenWidth >= 768; // Tailwind's md breakpoint

    let dynamicStyle: Style = {};

    if (refImageContainer.current && refLines.current && refFace.current) {
      const imageContainerWidth = refImageContainer.current.getBoundingClientRect().width;
      const faceWidth = refFace.current.getBoundingClientRect().width;
      const linesWidth = refLines.current.getBoundingClientRect().width;

      const offsetWidthDifferenceLines =
        Math.min(imageContainerWidth - linesWidth,- linesWidth / 8);

      const offsetWidthDifferenceFace =
        Math.min(imageContainerWidth - faceWidth, -faceWidth / 19);

      

      if (isMdBreakpoint) {
        dynamicStyle.right = `${offsetWidthDifferenceLines}px`;
        dynamicStyle.top = "0px";
        dynamicStyle.rightFace = `${offsetWidthDifferenceFace}px`;
      } else {
        if (refLines.current.offsetWidth < screenWidth) {
          dynamicStyle.right = `${-(refLines.current.offsetWidth / 3) * 1}px`;
        } else {
          
          dynamicStyle.right = `${-refLines.current.offsetWidth / 7}px`;
        }

        dynamicStyle.rightFace = `${-(refFace.current.offsetWidth / 3) * 1}px`;
        dynamicStyle.top = "0px";
      }
     
      setStyle(dynamicStyle);
    }


   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateStyleForBreakpoint);
    updateStyleForBreakpoint(); // Initial call

    return () => {
      window.removeEventListener("resize", updateStyleForBreakpoint);
    };
  }, [updateStyleForBreakpoint, imagesLoaded.heroImage]);

  const heroImagePath = "/face_2.svg";

  return (
    <div
      ref={ref}
      className="lg:pl-24 md:pl-8  md:flex-row md:items-start   flex flex-col h-full z-10 "
    >
      {/* Left Wrapper */}
      <div ref={textWrapperRef} className=" lg:pb-4 md:pb-8 lg:mt-32  md:mt-52 mt-16 md:mx-0 mx-8  flex-col">
        {/* Main Text Wrapper */}
        <motion.div
          variants={textVariantsContainer}
          initial="initial"
          animate="animate"
          className="md:items-start flex flex-col items-center"
          
        >
          <motion.div
            variants={textVariants}
            className=" md:items-start  lg:flex-row  xl:text-8xl md:text-7xl  items-center text-5xl  flex flex-col  font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 tracking-widest "
          >
            <h1 className="">Innovating</h1>
            <h1>Web</h1>
          </motion.div>



          <motion.h1
            variants={textVariants}
            className=" xl:text-7xl md:text-5xl  text-5xl  font-bold tracking-wider mt-4"
          >
            AI Solutions
          </motion.h1>

          <div className="mt-4 flex justify-start z-[49] md:text-xl">

            <button onClick={scrollToContact} className="  bg-white hover:bg-blue-900 text-black hover:text-white rounded-xl border border-white py-2 px-4 font-light transition duration-200 ease-in-out z-[60]">
              Contact Us
            </button>
          </div>
        </motion.div>

        {/* <motion.div
          className="text-50vh absolute -bottom-36 text-black-transperant text-nowrap w-2/4 font-bold  z-[0]"
          variants={slideTextVariants}
          initial="initial"
          animate="animate"
        >
          <h1>
          WEB DEVELOPMENT AND AI SOLUTIONS
          </h1>
         
        </motion.div> */}
      </div>

      {/* Right Wrapper - Adjusted for better responsiveness and scaling */}
      <div
        ref={refImageContainer}
        className="z-[2] md:static relative flex-grow flex-grow-1 mt-8 h-full"
      >
        <motion.div
        variants={heroImageVariants}
        initial="initial"
        animate="animate"
          ref={refFace}
          style={{ right: style.rightFace }}

          className="absolute top-0  h-full"
        >
          <motion.img
            // variants={floatingImageVariants}
            initial="initial"
            animate="animate"
            src={heroImagePath}
            alt="Hero Face"
            className="h-full object-cover" // Ensures image covers the area without overflowing
            onLoad={() => setImagesLoaded(prevState => ({ ...prevState, heroImage: true }))}
          />
        </motion.div>

        {/* Adjust HeroImageLines component similarly if needed */}
        <motion.div
          ref={refLines}
          style={{ right: style.right }}
          className={`absolute md:-top-36 h-full `}
        >
          <HeroImageLines/>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroSection;

