import React, { useState } from "react";
import { Variants, motion } from "framer-motion";
import Links from "./links/Links";
import ToggleButton from "./toggleButton/ToggleButton";

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const variants : Variants = {
    open: {
      clipPath: "circle(1500px at 24px 24px)",
      transition: { type: "spring", stiffness: 20, restDelta: 2},
    },
    closed: {
      clipPath: "circle(20px at 24px 28px)",
      transition: { type: "spring", stiffness: 400, damping: 40 },
    },
  };

  return (
    <div className="relative">


    <motion.div
      className="fixed flex flex-col content-center justify-center  text-black z-30"
      animate={isOpen ? "open" : "closed"}
      variants={variants}
    >
      <motion.div className="fixed top-0 left-0  b-0 w-80 h-full bg-white" 
      variants={variants}>
        <Links />
      </motion.div>
    <ToggleButton isOpen={isOpen} setIsOpen={setIsOpen} />

    </motion.div>
    </div>
  );
};

export default Sidebar;
