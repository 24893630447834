import React from "react";
import { motion } from "framer-motion";
import Sidebar from "../sidebar/Sidebar";

const NavBar: React.FC = () => {
  const pathVariants = {
    initial: {
      opacity: 0,
      pathLength: 0,
    },
    animate: {
      opacity: 1,
      pathLength: 0.5,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };


  
  return (
    <>
      <div className=" pb-4 flex">
        <Sidebar />

        <div className="  px-24 pt-5  flex-1">
          <motion.div
            initial="initial"
            animate="animate"
            className="text-xl font-bold text-white w-full flex align-middle justify-center md:justify-start"
          >
            {/* <motion.div
              className="hidden md:block h-16 w-16"
              variants={pathVariants}
            >
              <img className="" src="/Logo_Icon_White.svg" alt="Jive LTD Logo" />
            </motion.div> */}
            <motion.div
              className="w-full max-w-36  md:max-w-28  justify-center "
              variants={pathVariants}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 381.61 82.09"
              >
                <motion.path
                  d="M70.71,46.1c0,20.82-13.22,36-35.41,36C14.72,82.09,0,69.79,0,47H15c0,13.8,6.67,20.81,20.35,20.81S55.53,58.29,55.53,46.1v-32H31.27V0H70.71Z"
                  fill="#e6e6e6"
                  variants={pathVariants}
                />
                <motion.path
                  d="M125.33,80.48V0h15.06V80.48Z"
                  fill="#e6e6e6"
                  variants={pathVariants}
                />
                <motion.path
                  d="M238.58,80.83H222.83L189.37,0h17l24.26,62.66L255,0h17Z"
                  fill="#e6e6e6"
                  variants={pathVariants}
                />
                <motion.path
                  d="M381.61,80.48H321.36V0h60.25V14.72H336.42V33.23H380V47.37H336.42V65.54h45.19Z"
                  fill="#e6e6e6"
                  variants={pathVariants}
                />
              </svg>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
