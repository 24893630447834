import React from 'react';
import { motion } from 'framer-motion';

interface ToggleButtonProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isOpen, setIsOpen }) => {
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.button
      onClick={toggleSidebar}
      className="fixed top-2 left-0 w-12 h-10 bg-white text-black rounded-full flex items-center justify-center z-10"
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <motion.path
          fill="none"
          strokeWidth="3"
          stroke="black"
          strokeLinecap="round" 
          variants={{
            closed: { d: "M 3 12 L 21 12", opacity: 1 }, // Middle horizontal line
            open: { d: "M 6 6 L 18 18", opacity: 1 }, // Diagonal from top-left to bottom-right
          }}
        />
        <motion.path
          fill="none"
          strokeWidth="3"
          stroke="black"
          strokeLinecap="round"
          variants={{
            closed: { d: "M 3 6 L 21 6", opacity: 1 }, // Top horizontal line
            open: { d: "M 6 18 L 18 6", opacity: 1 }, // Diagonal from bottom-left to top-right
          }}
        />
        <motion.path
          fill="none"
          strokeWidth="3"
          stroke="black"
          strokeLinecap="round"
          variants={{
            closed: { d: "M 3 18 L 21 18", opacity: 1 }, // Bottom horizontal line
            open: { d: "M 12 12 L 12 12", opacity: 0 }, // Invisible middle line for closed state
          }}
        />
      </svg>
    </motion.button>
  );
};

export default ToggleButton;
