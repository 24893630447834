import { motion } from "framer-motion";
import React from "react";


const HeroImageLines: React.FC = () => {
  const drawLine = {
    initial: { pathLength: 0, opacity: 0 },
    animate: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { duration: 2, ease: "easeInOut" },
        opacity: { duration: 0.5 },
      },
    },
  };

  const drawDot = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        // Assuming line drawing takes 2 seconds, start showing dots after that
        delay: 2, // Delay the start of the dot animation to match the line animation duration
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  

  //   return (
  //     <motion.img
  //         src="/hero_lines.svg"
  //         alt="hero lines"
  //         className="w-full h-full"
  //         variants={svgVariants}
  //         initial="initial"
  //         animate="animate"
  //         />
  //   );

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360.34 393.15"
      className="w-full h-full"
      initial="initial"
      animate="animate"
      
    >
      <defs>
        <style>
          {`
          .cls-1 {isolation: isolate;}
          .cls-2 {mix-blend-mode: screen;}
          .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14 {fill: none; stroke-miterlimit: 10; stroke-width: 0.52px;}
          .cls-3 {stroke: url(#linear-gradient);}
          .cls-4 {stroke: #f0f;}
          .cls-5 {stroke: url(#linear-gradient-2);}
          .cls-6 {stroke: url(#linear-gradient-3);}
          .cls-7 {stroke: url(#linear-gradient-4);}
          .cls-8 {stroke: url(#linear-gradient-5);}
          .cls-9 {stroke: url(#linear-gradient-6);}
          .cls-10 {stroke: url(#linear-gradient-7);}
          .cls-11 {stroke: url(#linear-gradient-8);}
          .cls-12 {stroke: url(#linear-gradient-9);}
          .cls-13 {stroke: url(#linear-gradient-10);}
          .cls-14 {stroke: url(#linear-gradient-11);}
        `}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="117.93"
          y1="46.35"
          x2="383.76"
          y2="46.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f0f" />
          <stop offset="1" stopColor="#00f3ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="65.32"
          y1="78.7"
          x2="431"
          y2="78.7"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="51.15"
          y1="219.7"
          x2="416.06"
          y2="219.7"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="114.87"
          y1="362.65"
          x2="416.06"
          y2="362.65"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="55.35"
          y1="255.49"
          x2="383.76"
          y2="255.49"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="3.41"
          y1="325.56"
          x2="403.16"
          y2="325.56"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="180.99"
          y1="385.49"
          x2="404.19"
          y2="385.49"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="148.94"
          y1="11.22"
          x2="419.25"
          y2="11.22"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="87.88"
          y1="121.11"
          x2="406.12"
          y2="121.11"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="108.1"
          y1="152.4"
          x2="393.44"
          y2="152.4"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="20.72"
          y1="174.17"
          x2="395.32"
          y2="174.17"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g className="cls-1">
        <g id="Layer_2" data-name="Layer 2">
          <g id="OBJECTS">
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-3"
                points="383.76 52.23 330.75 52.23 318.98 40.47 117.93 40.47"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                
                d="M118.25,40.47a1.58,1.58,0,1,1-1.57-1.57A1.57,1.57,0,0,1,118.25,40.47Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-5"
                // points="65.32 68.76 208.94 68.76 228.82 88.64 289.35 88.64 300.42 77.57 431 77.57"
                points="431 77.57 300.42 77.57 289.35 88.64 228.82 88.64 208.94 68.76 65.32 68.76"
              />
              <motion.circle className="cls-4" cx="63.94" cy="68.76" r="1.57" variants={drawDot}/>
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-6"
                points="416.06 225.01 265.22 225.01 254.59 214.38 51.15 214.38"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M51.15,214.38a1.57,1.57,0,1,1-1.57-1.57A1.57,1.57,0,0,1,51.15,214.38Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-7"
                points="416.06 367.97 265.22 367.97 254.59 357.33 114.88 357.33"
              />
              <motion.circle variants={drawDot} className="cls-4" cx="113.3" cy="357.33" r="1.57" />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-8"
                points="383.76 239 285.88 239 252.88 271.99 55.35 271.99"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M55.35,272a1.58,1.58,0,1,1-1.57-1.57A1.57,1.57,0,0,1,55.35,272Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-9"
                points="403.16 339.83 252.61 339.83 224.05 311.28 3.41 311.28"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M3.41,311.28a1.58,1.58,0,0,1-3.15,0,1.58,1.58,0,1,1,3.15,0Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-10"
                points="404.19 379.68 298.08 379.68 286.55 391.31 180.99 391.31"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M181,391.31a1.58,1.58,0,1,1-1.58-1.57A1.57,1.57,0,0,1,181,391.31Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-11"
                points="419.25 0.26 313.14 0.26 291.22 22.18 148.94 22.18"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M148.94,22.18a1.58,1.58,0,1,1-1.58-1.57A1.57,1.57,0,0,1,148.94,22.18Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-12"
                points="406.12 138.54 272.37 138.54 237.5 103.67 87.88 103.67"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M87.88,103.68a1.58,1.58,0,1,1-1.58-1.58A1.58,1.58,0,0,1,87.88,103.68Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-13"
                points="393.44 175.31 258.56 175.31 212.74 129.49 108.1 129.49"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M108.1,129.49a.84.84,0,1,1-.84-.84A.84.84,0,0,1,108.1,129.49Z"
              />
            </g>
            <g className="cls-2">
              <motion.polyline
                variants={drawLine}
                className="cls-14"
                points="395.32 199.29 240.4 199.29 209.21 168.1 91.41 168.1 72.36 149.04 20.72 149.04"
              />
              <motion.path
              variants={drawDot}
                className="cls-4"
                d="M20.72,148.94a.84.84,0,1,1-.83-.84A.84.84,0,0,1,20.72,148.94Z"
              />
            </g>
          </g>
        </g>
      </g>
    </motion.svg>
  );
};

export default HeroImageLines;
