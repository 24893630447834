import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { slideInFromRight } from '../../../../utils/motion';

interface WhatWeDoTextProps {
  heading: string;
  describeText: string;
}

const WhatWeDoText: FC<WhatWeDoTextProps> = ({ heading, describeText }) => {
  return (
    <div className="mx-auto max-w-2xl w-full items-center flex flex-col md:text-start text-center">
      <motion.h1
        variants={slideInFromRight(0, 1.6)}
        className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold text-white mb-6"
      >
        {heading}
      </motion.h1>
      <motion.p
        variants={slideInFromRight(0, 1.6)}
        className="text-lg sm:text-xl md:text-2xl text-white leading-normal"
      >
        {describeText}
      </motion.p>
    </div>
  );
};

export default WhatWeDoText;
