import { Variants, motion } from "framer-motion";
import React, { useRef } from "react";

const circleVariants: Variants = {
  hidden: { scale: 0, opacity: 0 },
  show: {
    cx: "50%",
    cy: "50%",
    scale: [0, 1],
    opacity: [1, 0], // Fade out effect
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration: 2, // Adjust duration based on desired effect
      ease: "easeInOut",
    },
  },
};

// Updated variants to include staggerChildren
const containerVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 1, // Delay between each child animation
    },
  },
};

const WaterDropEffectAnimation: React.FC<{ numCircles: number }> = ({
  numCircles,
}) => {
  const svgRef = useRef<HTMLDivElement>(null);

  // Function to render multiple divs based on numCircles
  const renderCircles = () => {
    return Array.from({ length: numCircles }, (_, i) => (
      <motion.div
        key={i}
        variants={circleVariants}
        className="w-1/2 aspect-square absolute rounded-full border-white/500 border-2 bg-transparent shadow-[0_30px_30px_-5px_rgba(0,0,0,5)]
        "
      />
    ));
  };

  return (
    <motion.div
      ref={svgRef}
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className="w-full h-full flex justify-center items-center relative"
    >
      {renderCircles()}
    </motion.div>
  );
};

export default WaterDropEffectAnimation;
