import ArtificialIntelligence from "./ArtificialIntelligence";
import WebAndMobileDevelopment from "./WebAndMobileDevelopment";

const WhatWeDo = () => {

  return (
    <div className=" h-auto relativ mt-24 px-8 xl:px-36 lg:px-24">
      {/* Progress */}
      <ArtificialIntelligence />
      <WebAndMobileDevelopment />
    </div>
  );
};

export default WhatWeDo;
