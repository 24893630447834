// Context.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  RefObject,
} from "react";

interface ElementRefsContextType {
  elementRefs: {
    heroTextWrapper: RefObject<HTMLElement> | null;
    ref2: RefObject<HTMLElement> | null;
  };
  registerRef: (
    key: "heroTextWrapper" | "ref2",
    ref: RefObject<HTMLElement>
  ) => void;
}

const ElementRefsContext = createContext<ElementRefsContextType | null>(null);

export const useElementRefs = (): ElementRefsContextType => {
  const context = useContext(ElementRefsContext);
  if (!context) {
    throw new Error(
      "useElementRefs must be used within an ElementRefsProvider"
    );
  }
  return context;
};

interface Props {
  children: ReactNode;
}

export const ElementRefsProvider: React.FC<Props> = ({ children }) => {
  const [elementRefs, setElementRefs] = useState<{
    heroTextWrapper: RefObject<HTMLElement> | null;
    ref2: RefObject<HTMLElement> | null;
  }>({ heroTextWrapper: null, ref2: null });

  const registerRef = (
    key: "heroTextWrapper" | "ref2",
    ref: RefObject<HTMLElement>
  ) => {
    setElementRefs((prevRefs) => ({ ...prevRefs, [key]: ref }));
  };

  return (
    <ElementRefsContext.Provider value={{ elementRefs, registerRef }}>
      {children}
    </ElementRefsContext.Provider>
  );
};
